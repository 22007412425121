<template>
  <vue-final-modal
    v-model="modalsSync[ModalName.TG_NEWS]"
    class="modal-tg-news"
    :clickToClose="false"
    :focus-trap="false"
  >
    <div class="modal-tg-news__container">
      <div class="modal-tg-news__top">
        <img :src="`${gamehubCdn}/images/tg-news/tg-news.svg`" width="193" height="132" alt="banner" />
        <div class="modal-tg-news__top-txt">
          {{ getContent(popupsData, defaultLocalePopupsData, 'tgNews.topTxt') }}
        </div>
      </div>
      <div class="modal-tg-news__info">
        <div class="modal-tg-news__title">
          {{ getContent(popupsData, defaultLocalePopupsData, 'tgNews.title') }}
        </div>
        <div class="modal-tg-news__desc" v-html="description" />
        <button-base class="modal-tg-news__got-it" type="primary" @click="closeModalSync(ModalName.TG_NEWS)">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.gotIt') }}
        </button-base>
        <button class="modal-tg-news__dont-show" @click="onClickDontShow">
          {{ getContent(popupsData, defaultLocalePopupsData, 'wallet.tgNews.dontShow') }}
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>
<script setup>
  import { VueFinalModal } from 'vue-final-modal';

  const {
    public: { apiBaseUrl, gamehubCdn },
  } = useRuntimeConfig();
  import { ModalName } from '@skeleton/consts/modals';
  const { popupsData, defaultLocalePopupsData, layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());
  const modalStore = useModalStore();
  const { closeModalSync } = modalStore;
  const { modalsSync } = storeToRefs(modalStore);
  const { getContent } = useProjectMethods();

  const description = computed(() => {
    let desc = getContent(popupsData.value, defaultLocalePopupsData.value, 'tgNews.description');

    if (desc) {
      desc = desc.replace('{url}', apiBaseUrl);
    }

    return desc;
  });

  const onClickDontShow = () => {
    localStorage.setItem('tg-news', 'true');
    closeModalSync(ModalName.TG_NEWS);
  };
</script>

<style src="~/assets/styles/components/modal/tg-news.scss" lang="scss" />
